
  import { Component, Watch } from 'vue-property-decorator'
  import { GForm } from '@/components/forms/GForm'
  import { plainToInstance } from 'class-transformer'
  import { Auto } from '@/entities/public'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { Form } from '@/entities/public/Resource/metadata'
  import { isValidNumber, parseToNumber, stringifySafe, updateNestedObject } from '@/utils/general'
  import { Debounce } from '@/utils/decorators'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import dayjs from 'dayjs'
  import _ from 'lodash'
  import { ComponentValue, VersionYear } from '@/entities/vehicle'
  import { mapActions } from 'vuex'
  import InspectionSummary from '@/components/forms/inspection/InspectionSummary.vue'

@Component({
  components: { InspectionSummary, GAlert, BaseForm },
  methods: {
    ...mapActions('resources/form', ['findPpu', 'findVersionSuggestionByPPU', 'getActivePurchaseDealByRegistrationPlate']),
    ...mapActions('resources/form', ['findPpu', '']),
  },
  computed: {},
})
  export default class AutoForm extends GForm {
  getActivePurchaseDealByRegistrationPlate!: (ppu: string) => Promise<Record<string, any>>

  autoInfo = {
    brand: null,
    models: null,
    versions: null,
    year: null,
  }

  autoInfoAux: Auto | null = null

  autos = []
  autoDetail = null
  showInspectionDetail = false
  inspection = null
  disabledSend = false
  loadingForm = false
  appraisal = null
  autoIsNotMatch = false
  existVersion = false
  existVersionFound = null
  loading = false
  previousAuto = {
    open: false,
    title: 'Este vehículo esta registrado en nuestra ',
    subtitle: '',
  }

  ppuMandatory = false
  validPpuLetter = null
  isPathStock = false
  maxYear = null
  auto: Auto = plainToInstance(Auto, {})
  temporalAuto: Auto = plainToInstance(Auto, {})
  disabledFields = false
  displayGeneration = false
  isDeprecatedSelected = false
  suggestedVersion = null
  fuels: ComponentValue[] = []
  maxVehicleAgeAllowed = 2
  transmissions: ComponentValue[] = []
  traction: ComponentValue[] = []
  addSuggestion = false
  isPurchaseOrder = false
  message = ''
  messageInfo = ''
  messageNotMatch = ''
  autoAlert = {
    open: false,
    subtitle: '',
    title: '',
  }

  showDetail = false
  title = ''
  auxFormData = {
    ppu: null,
    brand: null,
    model: null,
    version: null,
    year: null,
    generation: null,
    newModel: null,
    newVersion: null,
  }

  formData = {
    ppu: null,
    brand: null,
    model: null,
    version: null,
    year: null,
    generation: null,
    newModel: null,
    newVersion: null,
  }

  form = {
    fuel: null,
    transmission: null,
    traction: null,
    cylinder: null,
  }

  fields = {
    brand: {
      properties: {},
      items: [],
      rules: [],
    },
    model: {
      properties: {},
      items: [],
      rules: [],
    },
    ppu: {
      properties: {},
      items: [],
      rules: [],
    },
    version: {
      properties: {},
      items: [],
      rules: [],
    },
    generation: {
      properties: {},
      items: [],
      rules: [],
    },
    year: {
      properties: {},
      items: [],
      rules: [],
    },
  }

  newModelRules = []
  newVersionRules = []

  findPpu!: (ppu: string) => Promise<any>
  findVersionSuggestionByPPU!: (ppu: string) => Promise<string>

  metadata = {}
  metadataCollection = {}

  async mounted () {
    this.loadingForm = true
    await this.setMetadata()
    const { id, title, metadataCollection } = this

    if (isValidNumber(id)) {
      await this.getAutoInfo(id)
    }

    this.setTheBackup()

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadataCollection, title, Boolean(id))
    }

    this.isPathStock = this.crumbs.some(crumb => crumb?.resource?.slug === 'supervisor_stock')
    this.setLoadingFalse()
  }

  @Debounce(500)
  setLoadingFalse (): void {
    this.loadingForm = false
  }

  async getAutoInfo (id) {
    if (!id) return
    const auto = await this.fetchData({
      query: { name: 'fetch', model: 'Auto', params: { id } },
      force: true,
    })
    let isNotQualify = false

    if (this.uid) {
      this.appraisal = await this.fetchData({
        query: { name: 'fetch', model: 'Appraisal', params: { id: this.uid } },
        force: true,
      })
    }

    if (this.appraisal) {
      const { deal: { closingReason } } = this.appraisal

      isNotQualify = closingReason?.name === 'not_qualify'
    }

    if (auto && !isNotQualify) {
      this.formData.year = auto.version.year.id
      this.formData.brand = auto.version.version.model.brand
      this.fields.brand.items = [auto.version.version.model.brand]
      this.formData.model = auto.version.version.model
      this.fields.model.items = [auto.version.version.model]
      this.formData.version = auto.version
      this.fields.version.items = [auto.version]
      this.formData.ppu = auto?.registrationPlate
      this.formData.generation = auto.generation
      this.fields.generation.items = auto?.generation ? [auto.generation] : []
      this.auto = auto
    }
  }

  async setMetadata () {
    const { metadata } = this.getForm('Auto', 'auto')
    const { fields, form } = metadata as Form

    this.title = form.title
    this.metadataCollection = metadata
    this.fields.brand.properties = fields.brand.properties
    this.fields.model.properties = fields.model.properties
    this.fields.version.properties = fields.version.properties
    this.fields.year.properties = fields.year.properties
    this.fields.ppu.properties = fields.ppu.properties
    this.fields.generation.properties = fields.generation.properties

    this.fields.year.items = await this.fetchData({
      query: { name: 'find', model: 'Year' },
    })

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'auto' } },
    })

    this.ppuMandatory = process?.[0]?.config?.ppuMandatory
    this.validPpuLetter = process?.[0]?.config?.validPpuLetter
    this.maxYear = process?.[0]?.config?.maxYear
    this.maxVehicleAgeAllowed = process?.[0]?.config?.maxVehicleAgeAllowed
  }

  setTheBackup () {
    const { backup } = this

    if (backup && 'auto' in backup && backup.auto) {
      this.formData.year = backup.auto.version.year
      this.formData.brand = backup.auto.version.version.model.brand
      this.formData.model = backup.auto.version.version.model
      this.formData.version = backup.auto.version
      this.formData.ppu = backup.auto.registrationPlate
    }
  }

  get disabledPlate () {
    const { auto } = this

    return Boolean(auto?.registrationPlate) && auto.registrationPlate.length === 6 && Boolean(auto.id)
  }

  get isOtherModelSelected () {
    return this.formData.model && this.formData.model.id === undefined
  }

  get isOtherVersionSelected () {
    return this.formData.version && this.formData.version?.version?.name === 'Otra' && this.formData.version.id === undefined && !this.addSuggestion
  }

  async validateNewModel () {
    if (this.loadingForm || !this.formData.newModel) return
    const newModelProcessed = this.processModelName(this.formData.newModel)
    const existingModels = this.fields.model.items.map(item => this.processModelName(item.name))
    if (existingModels.includes(newModelProcessed)) {
      // El modelo ya existe
      this.newModelRules = [() => `El modelo '${this.formData.newModel}' ya existe en la lista`]
    } else {
      // No hay error
      this.newModelRules = []
    }

    if (this.formData.brand) {
      let items = await this.fetchData({
        query: {
          name: 'find',
          model: 'VersionYear',
          order: {
            version: { name: 'asc' },
          },
        },
        filter: {
          version: { model: { id_brand: { _eq: this.formData.brand.id } } },
        },
        force: true,
      }) as VersionYear[]
      items = _.uniqBy(items, item => item.version.name)
      this.fields.version.items = items
      this.fields.version.items.push({ id: undefined, version: { name: 'Otro' } })
    }
  }

  async validateNewVersion () {
    if (this.loadingForm || !this.formData.newVersion) return

    await this.onNewVersionChange(this.formData.newVersion)
    const newVersionProcessed = this.processModelName(this.formData.newVersion)
    const existingVersions = this.fields.version.items.map(item => this.processModelName(item.version?.name))

    if (existingVersions.includes(newVersionProcessed)) {
      this.newVersionRules = [() => `La versión '${this.formData.newVersion}' ya existe en la lista`]
    } else {
      this.newVersionRules = []
    }
  }

  async onNewVersionChange (newVersion) {
    if (!newVersion?.length) {
      this.existVersion = false
      return
    }
    this.loadingForm = true

    const existVersion = await this.fetchData({
      query: { name: 'find', model: 'VersionYear', order: { id: 'desc' } },
      filter: {
        _and: [
          {
            version: {
              _and: [
                { name: { _eq: `${newVersion}` } },
                { model: { id_brand: { _eq: this.formData.brand.id } } },
              ],
            },
          },
        ],
      },
      limit: 1,
      force: true,
    })

    if (existVersion?.id) {
      this.existVersionFound = await this.fetchData({
        query: { name: 'fetch', model: 'VersionYear', params: { id: existVersion?.id } },
        force: true,
      })
    }

    this.existVersion = Boolean(existVersion?.id)
    this.loadingForm = false
  }

  processModelName (name) {
    return name?.replace(/\s|-/g, '').toLowerCase().trim()
  }

  displayDetail () {
    const { auto, metadataCollection, formData } = this

    if (auto?.version?.year) {
      auto.version.year = formData.year
    }

    if (!auto?.version?.id) {
      auto.generation = null
    }
    this.metadata = {
      data: auto,
      metadata: metadataCollection,
    }

    this.showDetail = Boolean(auto?.version?.id) && Boolean(auto?.version?.version?.id)
  }

  saveBackup (backupAuto = null) {
    const { backup, auto, formData: { ppu }, id } = this

    if (backup) {
      auto.registrationPlate = ppu
      backup.auto = backupAuto || auto

      backup.isNewVehicle = id !== auto.id
      this.setBackup(backup)
    } else {
      this.setBackup({ auto, isValidNumber: id !== auto.id })
    }
  }

  async createNewVersion () {
    const cylinder = (await this.fetchData({
      query: { name: 'find', model: 'Component' },
      filter: {
        name: { _eq: 'Cilindrada' },
      },
    }))[0]
    const attrs = [{
      id_component: cylinder.id,
      value: this.form.cylinder?.toUpperCase(),
      validated: false,
    }].filter(attr => attr.value)
    const yearId = this.auxFormData.year?.id || this.auxFormData.year
    const version = await this.pushData({
      model: 'Version',
      fields: {
        name: this.auxFormData.newVersion.toUpperCase(),
        id_model: this.auxFormData?.model?.id,
        years: {
          data: [{
            id_year: yearId,
            attributes: {
              data: [
                ...attrs,
                {
                  id_component: this.form.fuel.component.id,
                  id_component_value: this.form.fuel.id,
                  validated: false,
                },
                {
                  id_component: this.form.transmission.component.id,
                  id_component_value: this.form.transmission.id,
                  validated: false,
                },
                {
                  id_component: this.form.traction.component.id,
                  id_component_value: this.form.traction.id,
                  validated: false,
                },
              ],
            },
          },
          ],
        },
      },
    })

    const versionYear = (await this.fetchData({
      query: { name: 'find', model: 'VersionYear' },
      filter: {
        id_version: { _eq: version.id },
        id_year: { _eq: yearId },
      },
      force: true,
    }))[0]

    const fullVersion = await this.fetchData({
      query: { name: 'fetch', model: 'VersionYear', params: { id: versionYear.id } },
      force: true,
    })
    this.formData.version = fullVersion
    this.auxFormData.version = fullVersion
    return fullVersion
  }

  async send () {
    const {
      ppuErrorMessage,
      newModelRules,
      newVersionRules,
      existVersion,
      formData,
      isPathStock,
    } = this

    this.auxFormData = _.cloneDeep(formData)

    const { year, version, newVersion } = this.auxFormData
    const idYear = year?.id || year

    const isNewVersionYear = idYear !== version?.year?.id
    const error = (ppuErrorMessage.length && ppuErrorMessage[0] !== 'Un usuario reportó que la versión no coincide. Te recomiendo confirmar con el cliente antes de avanzar') || newModelRules.length || newVersionRules.length

    if (!this.$refs.form.validate() || error) {
      await this.setFieldError()
      return
    }

    this.loadingForm = true
    if (isPathStock) {
      await this.handleStockChangeVersion(isNewVersionYear, existVersion)
      return
    }

    await this.closeAppraisalNewAuto()

    // Crear version year si no existe
    if (newVersion?.length && !existVersion) {
      this.auto.version = await this.createNewVersion()
      this.auto.generation = null
      this.saveBackup()
    } else if (existVersion || isNewVersionYear) {
      await this.createVersionYear()
    } else {
      await this.setAutoNewValues(this.auxFormData)
    }

    await this.checkAuto()
    await this.close()
  }

  async handleStockChangeVersion (isNewVersionYear, existVersion) {
    let versionYear = null
    let isNew = false
    const { newVersion } = this.auxFormData
    if (newVersion?.length && !existVersion) {
      this.auto.version = await this.createNewVersion()
      versionYear = this.auto.version
      this.auto.generation = null
      this.saveBackup()
      isNew = true
    } else if (existVersion || isNewVersionYear) {
      this.auto.version = await this.createVersionYear()
      versionYear = this.auto.version
      isNew = true
    } else {
      versionYear = this.formData.version
    }

    const autoExist = await this.fetchData({
      query: { name: 'find', model: 'Auto' },
      filter: {
        _and: [
          { registration_plate: { _eq: this.formData.ppu } },
        ],
      },
    })

    const available = (await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: {
        _and: [
          { process: { table_name: { _eq: 'auto' } } },
          { status: { name: { _eq: 'available' } } },
        ],
      },
    }))[0]

    const fields = {
      id_version_year: versionYear.id,
      id_generation: isNew ? null : (this.formData.generation?.id || null),
      id_process_status: available.id,
      registration_plate: this.formData.ppu,
    }

    const prevAuto = autoExist.find(auto => auto.version?.version.name === versionYear?.version.name)

    if (fields?.id_generation) {
      delete fields.id_version_year
    }

    await this.updateDeal(prevAuto, fields)
    await this.close()
  }

  async updateDeal (auto, fields) {
    let newAuto = null
    const stockDetails = (await this.fetchData({
      query: { name: 'find', model: 'StockViewDetails' },
      filter: {
          id_auto: {
            _eq: this.id,
          },
      },
    }))

    const stock = stockDetails.sort((a, b) => b.id - a.id)[0]
    await this.changeAutoNotMatch()
    await new Promise(resolve => setTimeout(resolve, 1000))

    if (!auto?.id) {
      newAuto = await this.pushData({
        model: 'Auto',
        fields: {
          ...fields,
          id_process_status: this.auto.status.id,
          last_price: this.auto.lastPrice,
        },
      })
      await new Promise(resolve => setTimeout(resolve, 1000))
    }

    const autoCreated = await this.pushData({
      model: 'Auto',
      fields: {
        id: newAuto?.id || auto?.id,
        id_process_status: this.auto.status.id,
        last_price: this.auto.lastPrice,
      },
    })
    await new Promise(resolve => setTimeout(resolve, 1000))

    await this.pushData({
      model: 'Deal',
      fields: {
        id: stock.idDeal,
        id_auto: autoCreated.id,
      },
    })
  }

  async checkAuto () {
    const { id } = this

    const { auxFormData } = this
    let auto = null
    if (id) {
      auto = await this.fetchData({
        query: { name: 'fetch', model: 'Auto', params: { id } },
        force: true,
      })
    }

    const fields = await this.getAutoFields(auxFormData, auto)
    const versionDifferent = auto?.version?.id !== fields?.id_version_year && fields?.id_version_year
    const generationDifferent = fields?.id_generation ? auto?.generation?.id !== fields?.id_generation && fields?.id_generation : false

    if ((id !== fields.id || (versionDifferent || generationDifferent)) && id) {
      await this.changeAutoNotMatch()
      this.auto.id = null
    }

    this.auto = await this.pushData({
      model: 'Auto',
      fields,
    })

    this.saveBackup()
  }

  buildAttributes (attributes: Record<string, any>[]) {
    if (!Array.isArray(attributes)) {
      throw new Error('Invalid attributes: Expected an array.')
    }

    return attributes.map(attr => {
      const hasValue = attr?.value !== undefined && attr?.value !== null
      const attributeData = {
        id_component: attr?.component?.id,
        validated: false, // Default value, can be adjusted
        value: null,
        id_component_value: null,
      }

      if (hasValue) {
        // If value exists, include it
        attributeData.value = attr.value
      } else if (attr.componentValue?.id) {
        // Include componentValue ID if available
        attributeData.id_component_value = attr.componentValue.id
      }

      return attributeData
    })
  }

  async createVersionYear () {
    const { year, generation: formGeneration } = this.auxFormData
    const yearId = year?.id || year
    if (!this.existVersionFound) {
      this.auxFormData.version = await this.fetchData({
        query: { name: 'fetch', model: 'VersionYear', params: { id: this.auxFormData.version.id } },
        force: true,
      })
    }

    const existVersion = (await this.fetchData({
      query: { name: 'find', model: 'VersionYear' },
      filter: {
        _and: [
          { id_version: { _eq: this.auxFormData.version.version.id } },
          { id_year: { _eq: yearId } },
        ],
      },
      force: true,
    }))[0]

    if (existVersion?.id) {
      this.auxFormData.version = await this.fetchData({
        query: { name: 'fetch', model: 'VersionYear', params: { id: existVersion?.id } },
        force: true,
      })
      this.saveBackupNewAuto(existVersion)
      return this.auxFormData.version
    }

    const { version, attributes, generations } = this.existVersionFound || this.auxFormData?.version

    const findGeneration = generations?.find(item => item.id === formGeneration?.id)

    const generation = findGeneration || (generations?.length ? generations[generations?.length - 1] : null)
    let generationFetched
    if (generation?.id) {
      generationFetched = await this.fetchData({
        query: { name: 'fetch', model: 'Generation', params: { id: generation?.id } },
      })
    }
    const customAttributes = generationFetched?.attributes || attributes

    const processedAttributes = this.buildAttributes(customAttributes)

    const fields = {
      id_version: version?.id,
      id_year: yearId,
      attributes: {
        data: processedAttributes,
      },
    }

    const newVersion = await this.pushData({
      model: 'VersionYear',
      fields,
    })

    this.saveBackupNewAuto(newVersion)
    return newVersion
  }

  saveBackupNewAuto (version) {
    const { backup, auxFormData: { ppu } } = this

    this.auto.version = version
    if (backup) {
      this.auto.registrationPlate = ppu
      backup.auto = this.auto
      this.setBackup(backup)
    } else {
      this.setBackup({ auto: this.auto })
    }
  }

  async checkVersion (formData) {
    let existVersion = null
    const yearId = formData.year?.id || formData.year
    existVersion = (await this.fetchData({
      query: { name: 'find', model: 'VersionYear' },
      filter: {
        _and: [
          { id_version: { _eq: formData.version.version.id } },
          { id_year: { _eq: yearId } },
        ],
      },
      force: true,
    }))[0]
    let existGeneration = null

    if (formData.generation?.id) {
      existGeneration = (await this.fetchData({
        query: { name: 'find', model: 'Generation' },
        filter: {
          _and: [{
            sku: { _eq: formData.generation.sku },
            version_year: { id_year: { _eq: yearId } },
          }],
        },
        force: true,
      }))[0]
    }

    if (!existVersion) {
      existVersion = await this.createVersionYear()
    }

    return { existVersion, existGeneration }
  }

  async getAutoFields (formData, auto = null) {
    const filter = {
      _and: [
        { registration_plate: { _eq: formData.ppu } },
        { id: { _neq: this.id } },
      ],
    }

    if (!this.id) {
      filter._and.splice(1, 1)
    }

    const autos = await this.fetchData({
      query: { name: 'find', model: 'Auto' },
      filter,
      force: true,
    })

    const { existVersion, existGeneration } = await this.checkVersion(formData)

    let restoreAuto = autos.find(auto => auto.version?.id === existVersion?.id && auto.generation?.id === existGeneration?.id)

    if (!restoreAuto && auto?.id) {
      restoreAuto = auto.version?.id === existVersion?.id && auto.generation?.id === existGeneration?.id ? auto : null
    }

    const statusAuto = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'auto' } } }, { status: { name: { _eq: 'deprecated' } } }] },
    })

    const fields = {
      id: autos.find(auto => auto.status.isNew)?.id || restoreAuto?.id,
      id_process_status: statusAuto?.[0]?.id,
      id_version_year: existVersion?.id,
      registration_plate: formData.ppu,
      id_generation: existGeneration?.id,
    }

    if (fields?.id_generation) {
      delete fields.id_version_year
    }

    if (fields?.id) {
      delete fields.id_version_year
      delete fields.registration_plate
      delete fields.id_generation
    }

    return fields
  }

  async setAutoNewValues (formData) {
    if (
      this.auto.generation?.id !== formData.generation?.id &&
      formData.generation?.id
    ) {
      this.auto.generation = formData.generation
      this.auto.version = formData.version
      this.auto.id = null
    } else if (this.auto.version?.id !== formData.version?.id) {
      this.auto.version = formData.version
      this.auto.generation = formData.generation
      this.auto.id = null
    }

    if (this.uid) {
      const appraisal = await this.fetchData({
        query: { name: 'fetch', model: 'Appraisal', params: { id: this.uid } },
        force: true,
      })

      if (appraisal?.deal?.closingReason?.id) {
        const fields = await this.getAutoFields(formData)

        const auto = await this.pushData({
          model: 'Auto',
          fields,
        })
        if (this.backup) {
          auto.registrationPlate = formData.ppu
          this.backup.auto = auto
          this.setBackup(this.backup)
        } else {
          this.setBackup({ auto })
        }
      }
    } else {
      this.saveBackup()
    }
  }

  @Watch('formData.year', { immediate: true })
  async onYearChange (year) {
    if (year) {
      if (!this.autoInfo && !this.backup?.auto) {
        this.formData.brand = null
        this.formData.model = null
        this.formData.version = null
        this.formData.generation = null
      } else if (!this.backup?.auto && !this.auto?.id) {
        this.fields.brand.items = [this.autoInfo?.brand]
        return
      }
      const { metadataCollection, formData, maxVehicleAgeAllowed } = this
      const { fields } = metadataCollection as Form
      if (fields) {
        const items = await this.fetchData({
          query: { name: 'find', model: 'Brand', order: { name: 'asc' } },
          filter: {
            models: {
              versions: {
                years: {
                  id_year: {
                    _gte: parseToNumber(this.formData.year) - parseToNumber(maxVehicleAgeAllowed),
                    _lte: parseToNumber(this.formData.year) + parseToNumber(maxVehicleAgeAllowed),
                  },
                },
              },
            },
          },
          force: true,
        })

        this.fields.brand.items = items

        if (formData.brand && !items.some(item => item.id === formData.brand?.id)) {
          formData.brand = null
        }
      }
    }
  }

  cleanModelSelection () {
    this.formData.model = null
    this.formData.version = null
    this.formData.generation = null
  }

  @Watch('formData.brand', { immediate: true })
  async onBrandChange (brand) {
    if (this.loadingForm) return
    this.cleanAuto()
    if (!this.backup?.auto && !this.auto?.id && !this.id) {
      this.cleanModelSelection()
    }

    if (brand) {
      if (!this.autoInfo?.brand && !this.backup?.auto && !this.auto?.id) {
        this.cleanModelSelection()
      } else if (!this.backup?.auto) {
        this.fields.model.items = this.autoInfo?.models
        // Other model option if is required
        // this.fields.model.items.push({ id: undefined, name: 'Otro' })
        if (this.autoInfo?.models?.length === 1) {
          this.formData.model = this.autoInfo.models[0]
        }
        return
      }

      const { metadataCollection, maxVehicleAgeAllowed } = this
      const { fields } = metadataCollection as Form
      if (fields) {
        const yearId = this.formData.year?.id || this.formData.year
        const items = await this.fetchData({
          query: {
            name: 'find',
            model: 'Model',
            order: {
              name: 'asc',
            },
          },
          filter: {
            _and: [
              { id_brand: { _eq: brand.id } },
              {
                versions: {
                  years: {
                    id_year: {
                      _gte: parseToNumber(yearId) - parseToNumber(maxVehicleAgeAllowed),
                      _lte: parseToNumber(yearId) + parseToNumber(maxVehicleAgeAllowed),
                    },
                  },
                },
              },
            ],
          },
          force: true,
        })
        this.fields.model.items = items
        // this.fields.model.items.push({ id: undefined, name: 'Otro' })
        if (this.formData.model && !items.some(item => item.id === this.formData.model?.id)) {
          this.formData.model = null
        }
      }
    }
  }

  @Watch('formData.model', { immediate: true })
  async onModelChange (model) {
    this.cleanAuto()
    if (!this.backup?.auto && !this.auto?.id) {
      this.formData.version = null
      this.formData.generation = null
    }
    if (model?.id) {
      if (!this.autoInfo && !this.backup?.auto && !this.auto?.id) {
        this.formData.version = null
      }
      const { metadataCollection, maxVehicleAgeAllowed } = this
      const { fields } = metadataCollection as Form
      if (fields) {
        const yearId = this.formData.year?.id || this.formData.year
        const items = await this.fetchData({
          query: {
            name: 'find',
            model: 'VersionYear',
            order: {
              id_year: 'desc',
            },
          },
          filter: {
            _and: [
              {
                version: {
                  _and: [
                    {
                      model: { _and: [{ id_brand: { _eq: this.formData.brand.id } }, { id: { _eq: model?.id } }] },
                    },
                  ],
                },
              },
              {
                id_year: {
                  _gte: parseToNumber(yearId) - parseToNumber(maxVehicleAgeAllowed),
                  _lte: parseToNumber(yearId) + parseToNumber(maxVehicleAgeAllowed),
                },
              },
            ],
          },
          force: true,
        })

        const autoVersion = this.autoInfo?.versions?.[0] || this.auto?.version || this.temporalAuto?.version || this.autoDetail?.version
        this.fields.version.items = this.sortVersionYear(items)

        const pos = this.fields.version.items.findIndex(item => parseToNumber(item.version.id) === parseToNumber(autoVersion?.version?.id) && parseToNumber(item.year.id) !== parseToNumber(autoVersion?.year))
        if (pos !== -1) {
          this.fields.version.items[pos] = autoVersion?.version
        }
        this.fields.version.items.push({ id: undefined, version: { name: 'Otra' } })
        if (!this.backup?.auto) {
          this.formData.version = autoVersion?.version
          this.checkVersionDeprecated(this.formData.version)
        }
      }
    }
    this.fields.version.items = this.fields.version.items.filter(item => item.version?.name)
  }

  sortVersionYear (data) {
    // Hacemos una copia del array original para evitar mutaciones
    const dataCopy = [...data]

    // Paso 2: Crear un mapa para almacenar versiones únicas
    const uniqueVersionsMap = new Map()

    // Paso 3: Iterar sobre el array ordenado y llenar el mapa
    for (const item of dataCopy) {
      const versionName = item.version.name
      if (!uniqueVersionsMap.has(versionName)) {
        uniqueVersionsMap.set(versionName, item)
      }
    }

    // Paso 4: Extraer los valores del mapa
    const uniqueItems = Array.from(uniqueVersionsMap.values())

    // Paso 5: Ordenar los items por 'version.name' ascendentemente
    uniqueItems.sort((a, b) => a.version.name.localeCompare(b.version.name))

    return uniqueItems
  }

  cleanVersion () {
    this.cleanAuto()
    if (!this.backup?.auto) {
      this.auto.version = null
    }
  }

  cleanVersionSelectionData () {
    this.formData.newVersion = null
    this.formData.generation = null
    this.displayGeneration = false
    this.existVersion = false
    this.auto.version = null
    this.auto.generation = null
  }

  @Watch('formData.version', { immediate: true, deep: true })
  async onVersionChange (version, oldVersion) {
    if (!version) {
      this.cleanVersion()
      return
    }
    this.cleanVersionSelectionData()
    if (version?.id && oldVersion?.id !== version?.id) {
      this.cleanAuto()
      if (!this.backup?.auto) {
        this.formData.generation = null
      }
      const generation = await this.fetchData({
        query: { name: 'find', model: 'Generation' },
        filter: { id_version_year: { _eq: version.id } },
      })

      if (generation?.length > 1) {
        this.displayGeneration = true
        this.fields.generation.items = generation
      } else if (generation?.length === 1) {
        this.fields.generation.items = []
        this.displayGeneration = false
        this.auto.generation = generation?.[0]
        this.formData.generation = generation?.[0]
      } else {
        this.formData.generation = null
        this.displayGeneration = false
        this.fields.generation.items = []
      }
    }

    this.auto.registrationPlate = this.formData.ppu
    this.auto.version = _.cloneDeep(version)
    this.checkVersionDeprecated(version)
    this.displayDetailWithDelay()
  }

  checkVersionDeprecated (version) {
    const { formData: { year } } = this
    const autos = this.autos.filter(auto => auto.status.isNotMatch)

    if (autos?.length && autos.find(auto => auto.version.version.id === version?.version?.id && auto?.version?.year?.id === (year?.id || year))) {
      this.messageNotMatch = 'Un usuario reportó que la versión no coincide. Te recomiendo confirmar con el cliente antes de avanzar'
    } else {
      this.messageNotMatch = ''
    }
  }

  @Watch('formData.generation', { immediate: true, deep: true })
  async onGenerationChange (generation, oldGeneration) {
    if (generation?.id && oldGeneration?.id && oldGeneration?.id !== generation?.id) {
      this.auto.generation = null
      this.displayDetail()
    }
    if (generation) {
      this.auto.generation = generation
    }
    this.displayDetailWithDelay()
  }

  @Debounce(500)
  displayDetailWithDelay () {
    this.displayDetail()
  }

  @Watch('formData.ppu', { immediate: true })
  async onPpuChange (ppu) {
    if (ppu?.length === 6) {
      this.isDeprecatedSelected = false
      await this.searchAuto(ppu)
    }
  }

  async getAutos (query, input) {
    this.autos = await this.fetchData({
      query: { name: 'find', model: 'Auto', order: { id: 'desc' } },
      filter: { ...query },
      force: true,
      limit: 100,
    })

    if (this.autos.length) {
      this.appraisal = await this.fetchData({
        query: { name: 'find', model: 'Appraisal', order: { id: 'desc' } },
        filter: { deal: { id_auto: { _eq: this.autos[0]?.id } } },
        limit: 1,
        force: true,
      })
    }

    const purchaseOrder = await this.fetchData({
      query: { name: 'findLite', model: 'PurchaseOrder' },
      filter: {
        _and: [
          { negotiation: { inspection: { appraisal: { deal: { auto: { registration_plate: { _eq: input } } } } } } },
          { id_closing_reason: { _is_null: true } },
        ],
      },
      force: true,
    })

    this.isPurchaseOrder = purchaseOrder.length

    return this.autos[0]
  }

  initializeValues () {
    this.loading = true
    this.cleanAuto()
    this.message = ''
    if (!this.backup?.auto && !this.auto?.id) {
      this.clearVehicleInfo()
    }
  }

  @Debounce(500)
  async searchAuto (input) {
    if (this.auto.registrationPlate !== input) {
      this.initializeValues()
    }

    if (!input?.length || input?.length < 6) {
      this.loading = false
      return null
    }

    const { metadataCollection } = this

    const { fields } = metadataCollection as Form
    const query = updateNestedObject(fields.auto.computed.queries.items.where, '_eq', input)

    const auto = await this.getAutos(query, input)
    const { appraisal } = this

    if (this.autos?.length) {
      this.autoIsNotMatch = auto?.status?.isNotMatch

      if (!auto?.status?.isNotMatch && !appraisal) {
        await this.setAutoValues(auto, input)
      } else if (!appraisal) {
        await this.findPpuInDb(input)
        this.auto.registrationPlate = input
      } else {
        await this.checkAutoIsNotQualify(appraisal, input, auto)
      }
    } else {
      await this.findPpuInDb(input)
      this.auto.registrationPlate = input
      this.disabledSend = false
    }

    await this.getSuggestedPpu(input)
    this.loading = false
  }

  async getSuggestedPpu (ppu) {
    const version = (await this.findVersionSuggestionByPPU(ppu))?.[0]

    if (this.id) {
      this.autoInfoAux = await this.fetchData({
        query: { name: 'fetch', model: 'Auto', params: { id: this.id } },
        force: true,
      })
    }
    if (version) {
      this.suggestedVersion = version
    }
  }

  async setAutoValues (auto, ppu) {
    if (auto?.id === this.id || this.isPathStock) return
    const lead = (await this.getActivePurchaseDealByRegistrationPlate(auto?.registrationPlate))

    const isSameEmployee = lead.executive?.id === this.idEmployee

    const cannotBuy = !auto.status.isSold && !auto.status.isDeprecated && !auto.status.isRetired && !auto?.status?.isNew

    if (auto.status?.isBuying && !isSameEmployee) {
      this.message = `${lead.executive.person.firstName} ${lead.executive.person.surname} está ${auto.status.description} este vehículo, no podrás generar una tasación hasta que se cierre el lead activo`
    } else if (cannotBuy && !(auto.status.isBuying && this.isDirectPurchase)) {
      this.message = `El vehículo se encuentra en estado ${auto.status.description}, no puedes crear una tasación con este vehículo`
    } else if (!this.auto.id && !this.id) {
    this.temporalAuto = auto
    this.autoAlert = {
        open: true,
        title: `El vehículo con la patente: ${ppu} ya se encuentra registrado en nuestro sistema ¿desea continuar?`,
        subtitle: `${auto.alertName}`,
      }
      this.auto = auto
      this.autoInfoAux = _.cloneDeep(auto)
    }
  }

  async checkAutoIsNotQualify (appraisal, ppu, auto) {
    const notQualify = appraisal?.deal?.closingReason?.name === 'not_qualify'
    const isSamePPu = appraisal?.deal?.auto?.registrationPlate === ppu
    const notMatch = auto?.status?.isNotMatch
    if (notQualify && isSamePPu) {
      this.message = `El vehículo "No califica" para este negocio`
      this.disabledSend = true
      return
    } else if (!notMatch) {
      await this.setAutoValues(auto, ppu)
    } else {
      await this.findPpuInDb(ppu)
      this.auto.registrationPlate = ppu
    }
    this.disabledSend = false
  }

  async findPpuInDb (ppu) {
    if (isValidNumber(this.id) && this.id > 0) return

    this.messageInfo = ''
    const info = await this.findPpu(ppu)

    if (info?.auto?.year === -1) return

    if (!info?.auto?.year) {
      const { validPpuLetter } = this

      if (ppu?.length && validPpuLetter && !this.isValidLicensePlate(ppu)) {
        this.messageInfo = `Patente no figura en nuestro registro, ingresa la información manualmente`
      } else {
        this.setMessage(false, '')
      }
      return
    }

    this.autoInfo = info.auto

    const { auto: { brand, models, year, versions } } = info
    const { fields, maxYear } = this

    const currentYear = dayjs().year()

    if (maxYear && info.auto.year < currentYear - maxYear) {
      this.setMessage(true, `El vehículo ingresado tiene más de ${maxYear} años`)
      return
    } else {
      this.setMessage(false, '')
    }

    this.formData.year = fields.year.items.find(item => item.id === year)
    this.formData.brand = brand

    if (models?.length === 1) {
      this.formData.model = models[0]
      this.formData.version = plainToInstance(VersionYear, { version: versions[0] })
    } else {
      this.fields.model.items = models
    }
  }

  get disableBrand () {
    const { autoInfo } = this

    if (!autoInfo) {
      return false
    }

    const { brand } = autoInfo

    return Boolean(brand)
  }

  get disabledSendVersion () {
    const { auto, isOtherModelSelected, formData, isOtherVersionSelected, enableSend, existVersion } = this
    if (existVersion) {
      return false
    }

    if (isOtherModelSelected && !formData.newModel) {
      return true
    }
    if (isOtherVersionSelected && !formData.newVersion) {
      return true
    }
    return !auto.version?.id && !enableSend
  }

  get enableSend () {
    const { formData: { newVersion }, form: { traction, fuel, transmission } } = this

    return newVersion?.length && traction && fuel && transmission
  }

  get disableModel () {
    const { autoInfo } = this

    if (!autoInfo) {
      return false
    }

    const { models } = autoInfo

    return models?.length === 1
  }

  get disableVersion () {
    const { autoInfo } = this

    if (!autoInfo) {
      return false
    }

    const { versions } = autoInfo

    return versions?.length === 1
  }

  get disableYear () {
    const { autoInfo, loading } = this

    if (loading) {
      return true
    }

    if (!autoInfo) {
      return false
    }

    const { year } = autoInfo

    return Boolean(year)
  }

  get isPpuValid () {
    const { formData: { ppu } } = this

    return ppu?.length !== 6
  }

  get change () {
    const { formData } = this

    return stringifySafe([formData])
  }

  async setAuto (auto, flag = false) {
    this.auto = auto

    this.saveBackup(_.cloneDeep(auto))
    this.autoDetail = _.cloneDeep(auto)
    this.formData = {
      ppu: auto.registrationPlate,
      brand: auto.version.version.model.brand,
      model: auto.version.version.model,
      version: auto.version,
      year: auto.version.year,
      generation: auto.generation,
      newModel: null,
      newVersion: null,
    }

    this.autoAlert = {
      open: false,
      title: ``,
      subtitle: ``,
    }
    this.temporalAuto = null

    const appraisals = await this.fetchData({
      query: { name: 'find', model: 'Appraisal' },
      filter: { deal: { id_auto: { _eq: auto.id } } },
      force: true,
    })

    if (appraisals.length) {
      /*
      const appraisal = appraisals[appraisals.length - 1]

      const inspection = await this.fetchData({
        query: { name: 'find', model: 'Inspection', order: { id: 'desc' } },
        filter: {
          _and: [
            { id_appraisal: { _eq: appraisal.id } },
            { inspector_qualification: { _is_null: false } },
            { supervisor_qualification: { _is_null: false } },
          ],
        },
        force: true,
      })
      if (inspection?.length) {
        this.inspection = inspection[0]
        this.showInspectionDetail = true
      } else {
        this.closeForm()
      }
        */
      this.closeForm()
    } else if (flag && !this.id) {
      this.previousAuto = {
        open: true,
        title: `El vehículo con la patente: ${auto.ppu} ya se encuentra registrado en nuestro sistema ¿desea continuar?`,
        subtitle: `${auto.alertName}`,
      }
    } else if ((auto?.status?.isSold || auto?.status?.isRetired || auto?.status?.isDeprecated || auto?.status?.isBuying) && !this.id) {
      await this.close()
    }
  }

  cleanAuto () {
    if (this.loadingForm) return
    if (!this?.backup?.auto && !this.id) {
      this.auto = plainToInstance(Auto, {})
    }
    this.temporalAuto = null
    this.displayDetail()
  }

  cleanForm () {
    this.isDeprecatedSelected = true
    this.previousAuto = {
      open: false,
      title: '',
      subtitle: '',
    }
    this.cleanAuto()
    this.formData = {
      ppu: null,
      brand: null,
      model: null,
      version: null,
      year: null,
      generation: null,
      newModel: null,
      newVersion: null,
    }
    this.autoAlert = {
      open: false,
      title: ``,
      subtitle: ``,
    }
  }

  clearVehicleInfo () {
    this.autoInfo = null
    this.formData.brand = null
    this.formData.model = null
    this.formData.version = null
    this.formData.year = null
    this.formData.generation = null
  }

  isValidLicensePlate (ppu) {
    if (!ppu || typeof ppu !== 'string') return null
    if (ppu.length < 6) return false

    const firstLetter = ppu[0].toUpperCase()

    const { validPpuLetter } = this

    return firstLetter >= validPpuLetter.toUpperCase()
  }

  setMessage (flag, message) {
    this.disabledFields = flag
    this.message = message
  }

  @Watch('showInspectionDetail', { immediate: true })
  onShowInspectionDetailChange (showInspectionDetail, oldShowInspectionDetail) {
    if (!showInspectionDetail && oldShowInspectionDetail) {
      this.close()
    }
  }

  @Watch('formData', { immediate: true, deep: true })
  async onFormDataChange (formData) {
    if (this.isDeprecatedSelected) return

    // Verificar si existe algún auto en el array que coincida completamente
    const autoMatch = this.autos.filter(auto => !auto.status.isBuying && !auto.status.isDeprecated && !auto?.status?.isSold).some(auto => {
      const yearId = formData.year?.id || formData.year
      return (
        (!formData.generation || auto.generation?.sku === formData.generation.sku) &&
        (!formData.version || auto.version?.version?.name === formData.version.version?.name) &&
        (!formData.year || parseToNumber(auto.version?.year?.id) === parseToNumber(yearId)) &&
        (!formData.model || parseToNumber(auto.version?.version?.model?.id) === parseToNumber(formData.model.id)) &&
        (!formData.brand || parseToNumber(auto.version?.version?.model?.brand?.id) === parseToNumber(formData.brand.id))
      )
    })
    // Mensaje de error si no coincide ningún auto
    this.messageNotMatch = autoMatch
      ? 'Un usuario reportó que la versión no coincide. Te recomiendo confirmar con el cliente antes de avanzar'
      : ''

    if (autoMatch) {
      await this.getModels() // Opcional: Puedes actualizar los modelos si no hay coincidencias
    }

    // Si el auto está marcado como "deprecated", seleccionarlo automáticamente
    const deprecatedAuto = this.autos.find(auto => auto.status.isDeprecated)
    if (deprecatedAuto && this.auto?.id !== deprecatedAuto.id) {
      this.isDeprecatedSelected = true
      await this.setAuto(deprecatedAuto, true)
    }
  }

  @Watch('formData.newVersion', { immediate: true })
  async onDisplaySuggestedVersionChange (newVersion) {
    if (newVersion?.length && !this.transmissions.length) {
      this.transmissions = await this.fetchData({
        query: { name: 'find', model: 'ComponentValue' },
        filter: {
          component: {
            name: { _eq: 'Transmisión' },
          },
        },
      })

      this.traction = await this.fetchData({
        query: { name: 'find', model: 'ComponentValue' },
        filter: {
          _and: [
            { component: { name: { _eq: 'Tracción' } } },
            { value: { _neq: '4x2 y 4x4' } },
          ],
        },
      })

      this.fuels = await this.fetchData({
        query: { name: 'find', model: 'ComponentValue' },
        filter: {
          component: {
            name: { _eq: 'Combustible' },
          },
        },
      })
    }
  }

  @Watch('addSuggestion', { immediate: true })
  async onAddSuggestionChange (addSuggestion) {
    this.formData.newVersion = addSuggestion ? this.suggestedVersion : ''
  }

  @Watch('displaySuggestedVersion', { immediate: true })
  onDisplaySuggestedVersion (displaySuggestedVersion) {
    if (displaySuggestedVersion) {
      this.addSuggestion = true
      this.formData.newVersion = this.suggestedVersion
    } else {
      this.formData.newVersion = null
      this.addSuggestion = false
    }
  }

  async getModels () {
    const yearId = this.formData.year?.id || this.formData.year
    if (!this.formData?.brand?.id || !isValidNumber(yearId)) return
    const { metadataCollection } = this
    const { fields } = metadataCollection as Form
    if (fields) {
      const query = updateNestedObject(fields.model.computed.queries.items.where, '_eq',
        [
          { target: 3, value: this.formData.brand.id },
          { target: 2, value: yearId },
        ])
      this.fields.model.items = await this.fetchData({
        query: {
          name: 'find',
          model: 'Model',
          order: {
            name: 'asc',
          },
        },
        filter: { ...query },
        force: true,
      })
    }
  }

  closeForm () {
    this.close()
  }

  async changeAutoNotMatch () {
    const notMatchStatus = (await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: {
        _and: [
          { process: { table_name: { _eq: 'auto' } } },
          { status: { name: { _eq: 'not_match' } } },
        ],
      },
    }))[0]

    const autos = await this.fetchData({
      query: { name: 'find', model: 'Auto' },
      filter: {
        _and: [
          { registration_plate: { _eq: this.formData.ppu } },
          { id_process_status: { _neq: notMatchStatus?.id } },
        ],
      },
      force: true,
    })

    await Promise.all(autos.map(async auto => {
      return this.pushData({
        model: 'Auto',
        fields: {
          id: auto?.id,
          id_process_status: notMatchStatus?.id,
        },
      })
    }))
  }

  async closeAppraisalNewAuto () {
    if (!this.id) return

    await this.changeAutoNotMatch()

    const closingReason = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: {
        _and: [
          { type: { name: { _eq: 'not_qualify' } } },
          { status: { process: { table_name: { _eq: 'appraisal' } } } },
        ],
      },
    })

    const appraisals = await this.fetchData({
      query: { name: 'find', model: 'Appraisal', order: { id: 'desc' } },
      filter: { _and: [{ deal: { id_auto: { _eq: this?.id } } }, { id_closing_reason: { _is_null: true } }] },
      force: true,
    })

    if (appraisals?.length) {
    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { process: { table_name: { _eq: 'appraisal' } } },
    })
    await this.pushData({
      model: 'Appraisal',
      fields: {
        id: appraisals?.[0].id,
        id_process_status: status.find(status => status.name === 'closed')?.id,
        id_closing_reason: closingReason[0]?.id,
      },
    })

    const dealNotMatchStatus = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: {
        _and: [
          { process: { table_name: { _eq: 'deal' } } },
          { status: { name: { _eq: 'lost' } } },
        ],
      },
    })

    const closeReason = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: {
        _and: [
          { type: { name: { _eq: 'not_match' } } },
          { status: { process: { table_name: { _eq: 'deal' } } } },
        ],
      },
    })
      const deal = (await this.fetchData({
        query: { name: 'find', model: 'Deal' },
        filter: {
          appraisals: { id: { _eq: appraisals?.[0].id } },
        },
      }))[0]

      if (deal?.id) {
      await this.pushData({
        model: 'Deal',
        fields: {
          id: deal?.id,
          id_process_status: dealNotMatchStatus?.[0]?.id,
          id_closing_reason: closeReason?.[0]?.id,
        },
      })
    }
    }
  }

  get ppuErrorMessage () {
    const { messageNotMatch, message } = this

    if (message?.length) {
      return [message]
    }

    if (messageNotMatch?.length) {
      return [messageNotMatch]
    }

    return []
  }

  get isDirectPurchase () {
    const { crumbs, isPurchaseOrder } = this

    if (isPurchaseOrder) {
      return false
    }

    return crumbs.some(crumb => crumb?.resource?.api?.model === 'PurchaseOrder')
  }

  get displaySuggestedVersion () {
    const {
      suggestedVersion,
      formData: { model, newModel, version, brand },
      newModelRules,
      fields: { version: { items } },
      autoInfoAux,
    } = this

    if (items?.some(versionYear => versionYear?.version?.name === suggestedVersion)) {
      return false
    }

    const hasVersion = version?.hasOwnProperty('version') && version.version.hasOwnProperty('name') && !version?.id && !version?.version?.id
    if (!autoInfoAux && suggestedVersion?.length && hasVersion) {
      return true
    }

    const isSameModel = model?.id === autoInfoAux?.version?.version?.model?.id
    const isSameBrand = brand?.id === autoInfoAux?.version?.version?.model?.brand?.id

    if (!isSameModel || !isSameBrand) {
      return false
    }

    return suggestedVersion?.length && (model?.id || newModel?.length) && !newModelRules.length && hasVersion
  }

  get disabledVersion () {
    const { formData, disabledFields, message, isPpuValid, newModelRules } = this

    if (!formData.model?.id && !newModelRules.length && formData.newModel?.length) {
      return false
    }

    return !formData.model?.id || disabledFields || Boolean(message.length) || isPpuValid
  }

  get disabledAutoField () {
    const { auto } = this

    return Boolean(auto?.id)
  }
  }
